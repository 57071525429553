<template>
  <b-modal
    id="action-channel-modal"
    ref="action-channel-modal"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <div class="p-2">
      <div class="text-2xl font-bold text-[#333333] text-center">
        {{ modalData.title || '' }}
      </div>
      <div class="text-center my-1">
        {{ modalData.message || '' }}
      </div>
      <b-form
        v-show="typeAction !== 'delete'"
        @submit.prevent="onSubmit"
      >
        <b-form-group
          id="input-group-1"
          :label=" modalData.label || ''"
          label-for="input-1"
          label-class="font-bold text-[#333333]"
        >
          <b-form-input
            id="input-1"
            v-model="inputValue"
            :formatter="validateInput"
            :placeholder="modalData.placeholder || ''"
            required
          />
        </b-form-group>
        <div class="text-center items-end  flex gap-1 ">
          <b-button
            class="h-[38px]"
            block
            @click="$bvModal.hide('action-channel-modal')"
          >
            Batal
          </b-button>
          <b-button
            block
            type="submit"
            variant="primary"
            :disabled="disabledButton() || isLoading"
          >
            {{ modalData.button || '' }}
          </b-button>
        </div>
      </b-form>
      <div
        v-show="typeAction === 'delete'"
        class="text-center mt-5 items-end  flex gap-1 "
      >

        <b-button
          block
          variant="primary"
          :disabled="isLoading"
          @click="onSubmit"
        >
          Hapus Channel
        </b-button>
        <b-button
          class="h-[38px]"
          block
          variant="outline-primary"
          @click="$bvModal.hide('action-channel-modal')"
        >
          Batal
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { apiDeleteChannel, apiTopupKomchatCoin, apiSetTwoFactor } from '../service/api.whatsapp.services'

export default {
  props: {
    typeAction: {
      type: String,
      default: 'topup',
    },
    channelId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputValue: null,
      isLoading: false,
      modalContent: {
        delete: {
          title: 'Hapus Channel',
          message: 'Apakah anda yakin ingin menghapus channel ini?',
          button: 'Hapus Channel',
          label: '',
          placeholder: '',
          error: 'Delete channel gagal, Silahkan coba lagi!',
          sucess: 'Hapus channel berhasil',
        },
        topup: {
          title: 'Top-up Saldo',
          message: 'Masukkan nominal untuk top up Komchat Coin',
          button: 'TopUp',
          label: 'Nominal',
          placeholder: 'cth Rp1.000.000,-',
          error: 'Top up Komchat gagal, Silahkan coba lagi!',
          sucess: 'Top up berhasil',
        },
        twoFactor: {
          title: 'Set 2-Factor Authentication',
          message: 'Masukkan 6 Digit 2-Factor Authentication',
          button: 'Konfirmasi',
          label: '6 Digit 2FA',
          placeholder: 'cth 123456',
          error: 'Set 2-Factor Authentication Gagal, Silahkan coba lagi!',
          sucess: 'Set 2-Factor Authentication berhasil',
        },
      },
    }
  },
  computed: {
    modalData() {
      return this.modalContent[this.typeAction] || {}
    },
  },
  methods: {
    validateInput(value) {
      return value.replace(/\D/g, '')
    },
    async onSubmit() {
      try {
        this.isLoading = true
        const actions = {
          topup: () => apiTopupKomchatCoin(Number(this.inputValue), this.channelId),
          twoFactor: () => apiSetTwoFactor(this.inputValue, this.channelId),
          delete: () => apiDeleteChannel(this.channelId),
        }

        await (actions[this.typeAction] ? actions[this.typeAction]() : Promise.reject(new Error('Invalid action')))
        this.$toast_success({ message: this.modalData.sucess })
        this.isLoading = false
      } catch {
        this.$toast_error({ message: this.modalData.error })
      } finally {
        this.isLoading = false
        this.$emit('getDataChannel')
      }
    },
    disabledButton() {
      switch (this.typeAction) {
        case 'topup':
          return !this.inputValue || Number(this.inputValue) < 10000
        case 'twoFactor':
          return this.inputValue?.length !== 6
        default:
          return true
      }
    },
  },
}
</script>
