<template>
  <div>
    <b-button
      size="sm"
      class=" button-facebook button-login-facebook pl-1"
      @click="launchWhatsAppSignup"
    >
      Hubungkan
      <b-img
        width="70"
        src="https://storage.googleapis.com/komerce/assets/illustration/Meta-icon-side-white-komchat.svg"
        alt="logo-meta"
        srcset=""
      />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'FacebookLoginButton',

  props: {
    appId: {
      type: String,
      required: true,
    },
    configId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      FB: null,
    }
  },

  mounted() {
    this.loadFacebookSDK()
    window.addEventListener('message', this.handleMessage)
  },

  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },

  methods: {
    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: this.appId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v21.0',
        })
        this.FB = window.FB
      }

      const script = document.createElement('script')
      script.src = 'https://connect.facebook.net/en_US/sdk.js'
      script.async = true
      script.defer = true
      script.crossOrigin = 'anonymous'
      document.body.appendChild(script)
    },

    handleMessage(event) {
      if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
        return
      }

      try {
        const data = JSON.parse(event.data)
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          this.handleSignupEvent(data)
        }
      } catch (err) {
        console.log('Non JSON Response:', event.data)
      }
    },

    handleSignupEvent(data) {
      switch (data.event) {
        case 'FINISH': {
          const { phone_number_id, waba_id } = data.data
          this.$emit('signup-finished', { phone_number_id, waba_id })
          break
        }
        case 'CANCEL': {
          const { current_step } = data.data
          this.$emit('signup-cancelled', current_step)
          break
        }
        case 'ERROR': {
          const { error_message } = data.data
          this.$emit('signup-error', error_message)
          break
        }
        default: {
          console.log('Unhandled event:', data.event)
          break
        }
      }
    },

    fbLoginCallback(response) {
      if (response.authResponse) {
        const { code } = response.authResponse
        this.$emit('auth-success', code)
      } else {
        this.$emit('auth-failed')
      }
    },

    launchWhatsAppSignup() {
      if (!this.FB) {
        console.error('Facebook SDK not loaded')
        return
      }

      this.FB.login(this.fbLoginCallback, {
        config_id: this.configId,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: 'whatsapp_business_app_onboarding',
          sessionInfoVersion: '3',
        },
      })
    },
  },
}
</script>

<style scoped>
.button-facebook {
  display: flex !important;
  align-items: center;
  border-radius: 6px;
  padding: 4px 8px;
  cursor: pointer;
}

.button-login-facebook {
  border: 1px solid #0865FE !important;
  background-color: #0865FE !important;
  color: white;
}
</style>
