<template>
  <div
    v-if="!isLoading"
    class="border rounded-lg py-2 mt-2"
  >
    <div
      v-if="!dataNumber"
      class="ml-1 mb-1"
    >
      <facebookLoginButton
        :app-id="FB_APP_KOMCHAT_ID"
        :config-id="FB_CONFIG_KOMCHAT_ID"
        @auth-success="handleAuthSuccess"
        @auth-failed="handleAuthFailed"
        @signup-finished="handleSignupFinished"
        @signup-cancelled="handleSignupCancelled"
        @signup-error="handleSignupError"
      />
      <div class="border rounded-md mt-1 w-fit py-[6px] px-[4px]">
        <div
          class="bg-[#F4F4F4]  rounded-md  py-[4px] px-[20px] text-[#C2C2C2]"
        >
          <div>
            Belum Ada Nomor Terhubung
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-row
        v-for="(number, index) in dataNumber"
        :key="index"
        class="text-center  px-1 items-center"
      >
        <b-col>
          <div class="border rounded-md w-full lg:w-fit py-[6px] px-[4px]">
            <div
              class="bg-[#F4F4F4]  text-center rounded-md  py-[4px] px-[62px] text-[#C2C2C2]"
            >
              <div
                class="flex gap-2 justify-center items-center"
              >
                <div
                  :class="{ 'bg-[#1E7654]': number.status === 'CONNECTED', 'bg-[#F9AE2E]': number.status === 'PENDING', 'bg-[#CF2A08]': number.status !== 'CONNECTED' && number.status !== 'PENDING' }"
                  class="h-3 w-3 rounded-full"
                />
                <div class="font-semibold text-center text-black">
                  {{ number.phone_number }}
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="isAnynNumber"
          lg="5"
          class=""
        >
          <div class="flex my-2 md:my-0  justify-between lg:justify-around md:items-center">
            <div>
              <div class="font-semibold  mb-[0.5rem] uppercase text-black">
                STATUS NOMOR
              </div>
              <div class="flex gap-1 items-center">
                <b-img
                  width="24"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/Whatsapp.svg"
                /> <div class="text-black uppercase">
                  {{ number.status }}
                </div>
              </div>
            </div>
            <div>
              <div class="font-semibold  mb-[0.5rem] text-black">
                2 Factor Authentication
              </div>
              <div class="flex gap-1 items-center">
                <b-icon
                  class="text-[20px]  text-black"
                  icon="shield-lock"
                /> <div class="text-black">
                  {{ number.two_fa_status === 'inactive'? 'Tidak Aktif': 'Aktif' }}
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="isAnynNumber"
          class="lg:flex lg:justify-end"
        >
          <div>
            <div class="font-semibold text-start md:hidden mb-[0.5rem] text-black">
              Komchat Koin
            </div>
            <div class="flex items-center gap-1">
              <b-img
                width="24"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/dollar-circle.svg"
              />
              <div class="font-semibold text-black">
                IDR {{ number.komchat_coin }}
              </div>
              <b-dropdown
                id="dropdown-right"
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                right
              >
                <template #button-content>
                  <b-icon icon="three-dots-vertical" />
                </template>
                <b-dropdown-item
                  v-for="(item, indexDropDown) in dropdownList"
                  :key="indexDropDown"
                  @click="handleDropdown(item.value, number.id)"
                >
                  <div class="flex items-center gap-2">
                    <b-icon
                      class="text-[20px]  text-black"
                      :icon="item.icon"
                    />
                    <div class="text-black">
                      {{ item.text }}
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <action-channel-komchat-modal
      :type-action="typeAction"
      :channel-id="channelId"
      @getDataChannel="getNumberData"
    />
  </div>
  <div v-else>
    <b-card>
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </b-card>
  </div>
</template>

<script>
import { FB_APP_KOMCHAT_ID, FB_CONFIG_KOMCHAT_ID } from '../../static'

import facebookLoginButton from './facebook-login-button.vue'
import actionChannelKomchatModal from '../../modal/action-channel-komchat-modal.vue'
import { apiConnectFb, apiListDataNumber } from '../../service/api.whatsapp.services'

export default {
  components: {
    facebookLoginButton,
    actionChannelKomchatModal,
  },
  props: {
    tabActive: {
      type: String,
      default: 'brand',
    },
  },
  data() {
    return {
      isAnynNumber: false,
      isLoading: false,
      FB_APP_KOMCHAT_ID,
      FB_CONFIG_KOMCHAT_ID,
      dropdownList: [
        {
          icon: 'arrow-up-right',
          text: 'Recharge Komchat Coin',
          value: 'topup',
        },
        {
          icon: 'shield-lock',
          text: 'Set 2 Factor Authentication',
          value: 'twoFactor',
        },
        {
          icon: 'trash',
          text: 'Delete Channel ',
          value: 'delete',
        },
      ],
      params: {
        phone_number_id: '',
        waba_id: '',
        code: '',
      },
      dataNumber: [],
      typeAction: '',
      channelId: 0,
    }
  },
  async mounted() {
    if (this.tabActive === 'brand') {
      await this.getNumberData()
    }
  },
  methods: {
    handleAuthSuccess(code) {
      this.params.code = code
      this.sendParamsFb()
      this.$toast_success({ message: 'Auth Success' })
      // Handle authentication success
    },

    handleAuthFailed() {
      console.error('Signup cancelled')
      // this.$toast_error({ message: 'Auth Failed' })
      // Handle authentication failure
    },

    handleSignupFinished({ phone_number_id, waba_id }) {
      this.params.phone_number_id = phone_number_id
      this.params.waba_id = waba_id
      this.$toast_success({ message: 'Signup finished' })
      // Handle signup completion
    },

    handleSignupCancelled(step) {
      console.error('Signup cancelled:', step)
      // this.$toast_error({ message: `Signup cancelled at step ${step}` })
      // Handle signup cancellation
    },

    handleSignupError(error) {
      console.error('Signup error:', error)
      // this.$toast_error({ message: 'Signup error' })
      // Handle signup error
    },

    async sendParamsFb() {
      try {
        this.isLoading = true
        await apiConnectFb(this.params.phone_number_id, this.params.waba_id, this.params.code)
        this.getNumberData()
        this.isLoading = false
      } catch (err) {
        this.$toast_error({ message: 'Gagal konek ke meta' })
        this.isLoading = false
      }
    },

    async getNumberData() {
      try {
        this.isLoading = true
        const { data } = await apiListDataNumber()
        this.dataNumber = data.data
        this.isAnynNumber = true
        this.isLoading = false
        console.log(this.isLoading)
      } catch (err) {
        this.$toast_error({ message: 'Gagal ambil data nomor' })
        this.isLoading = false
      }
    },
    handleDropdown(value, id) {
      this.channelId = id
      this.typeAction = value
      this.$bvModal.show('action-channel-modal')
    },

  },
}
</script>
